import * as React from 'react'
import Layout from '../components/layout'
// import { StaticImage } from 'gatsby-plugin-image'
import {content, homeIcon, homePage, homeContainer } from '../components/layout.module.css'
import { Link, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAtlas, faSortAlphaDown, faCommentDots, faSearch } from '@fortawesome/free-solid-svg-icons'


const IndexPage = ({data}) => {
  return (
    <Layout pageTitle="Home">
      <p className={content}>Welcome to the Australian Dictionary of Invisible Culture for Teachers. 
      This site is designed for English language teachers in Australia to provide clear and accessible explanations for complex concepts. 
      If you have any suggestions, please use the suggestion box <Link to="/suggest">here</Link>.</p>
      {/* <StaticImage
        alt="Clifford, a reddish-brown pitbull, posing on a couch and looking stoically at the camera"
        src="../images/clifford.png"
      /> */}

      <br/>
      <br/>

      <div className={homeContainer}>
          <Link to="/by-topic" className={homePage}><FontAwesomeIcon className={homeIcon} icon={faAtlas} /><br/>Explore by <br/>Topic</Link>
          <Link to="/part-of-speech" className={homePage}><FontAwesomeIcon className={homeIcon} icon={faCommentDots} /><br/>Explore by <br/>Part of Speech</Link>
          <Link to="/entry-index" className={homePage}><FontAwesomeIcon className={homeIcon} icon={faSortAlphaDown} /><br/>View all entries <br/>alphabetically</Link>
          <Link to="/search" className={homePage}><FontAwesomeIcon className={homeIcon} icon={faSearch} /><br/>Search for <br/>an entry</Link>
      </div>



      </Layout>
  )
}

export const query = graphql`
query {
    chapterGroups: allMdx(sort: {fields: frontmatter___chapter, order: ASC}) {
      group(field: frontmatter___chapterTitle) {
        fieldValue
        nodes {
          frontmatter {
            sectionTitle
            title
            slug
          }
        }
      }
    }
  }
`

export default IndexPage